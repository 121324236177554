h3 {
  margin: 0;
  font-size: 40px;
  letter-spacing: 2px;
}

h4 {
  margin: 0;
  font-size: 30px;
  letter-spacing: 2px;
}

h5 {
  margin: 0;
  font-size: 30px;
  letter-spacing: 2px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 40px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  gap: 70px;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.hamburger {
  display: none;
  width: 30px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

header > div > h5, header > div > button {
  cursor: pointer;
}

.section-1 {
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/images/background.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

h1 {
  font-size: 200px;
  margin: 0;
}

h2 {
  font-size: 65px;
  margin: 0;
  letter-spacing: 3px;
}

.section-2 {
  padding: 25px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 80px 0 ;
}

.section-2 > h4 {
  width: 500px;
  color: #C4C4C4;
}

.section-2 > h4 > span {
  color: #FBF305;
}

.section-3 {
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: center;
  height: 700px;
}

.rrss-img {
  width: 300px;
}

.section-3-middle {
  display: flex;
  gap: 20px;
  position: relative;
}

.rrss-background {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
}

.section-3-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-3-top > h2 {
  width: 400px;
}

.section-3-top > h4 {
  width: 450px;
}

.white {
  color: #F4F4F4;
}

.section-4 {
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: 100px;
  overflow-x: hidden;
}

.section-4 > img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%); 
}

.section-4 > h3 {
  width: 450px;
}

.stay-tuned {
  margin-top: 100px;
}

small {
  color: #858BB3;
  font-size: 10px;
  width: 450px;
  margin-top: 260px;
}

a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  color: #FBF305;
}

a > button > h5 {
  color: black;
}

/* RESPONSIVE */

@media (max-width: 1200px) {
  .header-right {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    background: black;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    transform: translateX(100%);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .header-right.open {
    display: flex;
    transform: translateX(0);
    z-index: 100;
    padding: 0 20px;
  }

  .hamburger {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 100;
  }
}

@media (max-width: 900px) {
  .section-1 {
    height: 60vh;
  }
  .section-2 {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0;
    text-align: center;
  }

  .section-2 > img {
    width: 400px;
  }
}

@media (max-width: 780px) {
  h1 {
    font-size: 150px;
  }
  h2 {
    font-size: 40px;
  }
  .section-1 {
    text-align: center;
  }
}

@media (max-width: 670px) {
  h1 {
    font-size: 100px;
  }
  h2 {
    font-size: 40px;
  }
  .section-1 {
    text-align: center;
    height: 50vh;
  }
  .section-4 > img {
    width: 1100px;
  }
  .rrss-img {
    width: 200px;
  }
  small {
    margin-top: 150px;
  }

}

@media (max-width: 499px) {
  h1 {
    font-size: 100px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
  }
  .section-1 {
    text-align: center;
    height: 50vh;
  }

  .section-2 > h4 {
    font-size: 20px;
    width: 380px;
  }

  .section-3-top > h2 {
    width: 300px;
  }

  .section-3-top > h4 {
    width: 300px;
  }

  .section-4 > h3 {
    width: 300px;
  }

  small {
    width: 300px;
  }
}

