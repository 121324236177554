@font-face {
  font-family: 'Terror';
  src: url('./assets/fonts/terror.ttf');
}

body {
  margin: 0;
  font-family: 'Terror';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #0B0035 0%, #000000 41%, #0B0035 90.17%, #150064 100%);
  color: #FBF305;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

button {
  font-family: 'Terror';
  background-color: #FBF305;
  height: 65px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
